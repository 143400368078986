const cloudinaryBasePath = (type = 'image') => `https://res.cloudinary.com/djgjq4fl6/${type}/upload/`
export const getCloudinaryImageUrl = (imageId, width = 300, type = 'ORIGINAL_RATIO', optionalOptions = '') => {
	width = Math.round(width)
	const dppx = 2
	let options = '',
		size_params = ''
	if (type === 'RECTANGLE') {
		options = 'c_fill,g_auto,q_auto:eco,f_auto,dpr_' + dppx
		size_params = `,w_${width},ar_13:5`
	} else if (type === 'BIG_RECTANGLE') {
		options = 'c_fill,g_auto,q_auto:eco,f_auto,dpr_' + dppx
		size_params = `,w_${width},ar_8:5`
	} else if (type === 'THIN_RECTANGLE') {
		options = 'c_fill,g_auto,q_auto:eco,f_auto,dpr_' + dppx
		size_params = `,w_${width},ar_3:1`
	} else if (type === 'VERTICAL_RECTANGLE') {
		options = 'c_fill,g_auto,q_auto:eco,f_auto,dpr_' + dppx
		size_params = `,w_${width},ar_7:8`
	} else if (type === 'BIG_VERTICAL_RECTANGLE') {
		options = 'c_fill,g_auto,q_auto:eco,f_auto,dpr_' + dppx
		size_params = `,w_${width},ar_7:9`
	} else if (type === 'SQUARE') {
		options = 'c_fill,g_auto,q_auto:eco,f_auto,dpr_' + dppx
		size_params = `,w_${width},ar_1:1`
	} else if (type === 'PROFILE') {
		options = 'c_thumb,g_face,z_0.5,f_auto,dpr_' + dppx
		size_params = `,w_${width},ar_1:1`
	} else if (type === 'ROUND') {
		options = 'c_thumb,g_auto,z_0.5,f_auto,dpr_' + dppx
		size_params = `,w_${width},ar_1:1`
	} else if (type === 'ORIGINAL_RATIO') {
		options = 'q_auto,f_auto,dpr_' + dppx
		size_params = `,w_${width}`
	}
	return cloudinaryBasePath() + options + size_params + optionalOptions + '/v1/' + imageId
}
export const getCloudinaryVideoUrl = (videoId, type = 'ORIGINAL_RATIO') => {
	const dppx = 2
	let options = '',
		size_params = ''
	if (type === 'ORIGINAL_RATIO') {
		options = 'q_auto,f_auto:video,dpr_' + dppx
	}
	return cloudinaryBasePath('video') + options + size_params + '/v1/' + videoId
}
export const getOriginalCloudinaryUrl = (imageId, type) => {
	return cloudinaryBasePath(type) + 'v1/' + imageId
}

// If the value starts with data:image it is an image from the user's computer loaded through the FileReader.
export function isLocalImage(image) {
	if (!image) return false
	return image.substring(0, 10) === 'data:image'
}
